.container {
  display: flex;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}
.single_photo {
  padding: .1rem;
  width: 100%;
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}