@import 'src/styles/variables';
@import 'src/styles/animations';
@import 'src/styles/mixins/animationMixin';
@import 'src/styles/mixins/responsiveMixin';

.container {
  display: flex;
  justify-content: center;
  background: rgba(28,28,28, .97);
  align-items: center;
  padding: .5rem;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  &_logo {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
    img {
      width: 50%;
    }
    &_burger_menu {
      width: 40px;
      border-radius: 50%;
      background-color: $secondary;
      height: 40px;
      cursor: pointer;
      text-align: right;
      display: flex;
      justify-content: center;
    }
  }
  &_links {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    &__link {
      font-family: 'Roboto', Helvetica, sans-serif;
      font-weight: bold;
      color: $gold;
      text-decoration: none;
      padding: .5rem;
      &__active {
        color: $gold;
        font-family: 'Roboto', Helvetica, sans-serif;
        font-weight: bold;
        text-decoration: none;
        border-bottom: 1px solid $gold;
      }
    }
  }
  &_open_menu {
    max-height: 220px;
  }
  @include breakpoint(sm) {
    background: rgb(28, 28, 28);
    background: linear-gradient(180deg, rgba(28, 28, 28, 1) 0%, rgba(28, 28, 28, 0) 100%);
    padding: 0.1rem 1rem 5rem;
    &_links {
      flex-direction: row;
      padding: 0 2rem;
      max-height: initial;
      overflow: initial;
      &__link {
        color: $primary;
        &__active {
          border: none;
        }
      }
    }
    &_logo {
      display: flex;
      justify-content: center;
      img {
        width: initial;
      }
      &_burger_menu {
        display: none;
      }
    }
  }
}

.translation {
  @include breakpoint(sm) {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}