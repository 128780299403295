@import 'src/styles/variables';
@import 'src/styles/animations';

.container {
  color: #999;
  font: 'Roboto' 400 16px/1.5 exo, ubuntu, 'segoe ui', helvetica, arial, sans-serif;
  text-align: center;
  background: url($bg-url) repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 3s infinite;
  -moz-animation:    bg-scrolling-reverse 3s infinite;
  -o-animation:      bg-scrolling-reverse 3s infinite;
  animation:         bg-scrolling-reverse 3s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function:    linear;
  -o-animation-timing-function:      linear;
  animation-timing-function:         linear;
}