@import 'src/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', Helvetica, sans-serif;
  padding: 2rem 0;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: transparent;
  border: none;
  color: $gold;
  margin: 0 10px;
  cursor: pointer;
  img {
    width: 20px;
  }
}

.prev {
  img {
    transform: rotateZ(-90deg);
  }
}

.next {
  img {
    transform: rotateZ(90deg);
  }
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  opacity: 0.3;
  pointer-events: none;
  box-shadow: none;
  color: #999;
}