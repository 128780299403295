@import 'src/styles/variables';

.container {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 2px solid $primary;
  border-radius: 50%;
  position: fixed;
  right: 10px;
  padding: 5px;
  bottom: 50px;
  z-index: 1;
}