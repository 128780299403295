@import 'src/styles/variables';

.container {
  width: 100%;
  background-color: rgba(28, 28, 28, 0.95);
  position: fixed;
  bottom: 0;
  z-index: 1;
  &_inner {
    font-family: 'Roboto', Helvetica, sans-serif;
    color: $secondary;
    padding: 15px;
  }
}