.container {
  padding: 3rem;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 4em;
    margin: 0;
    font-family: 'Roboto', Helvetica, sans-serif;
  }
}