@import 'src/styles/variables';
@import 'src/styles/animations';
@import 'src/styles/mixins/animationMixin';
@import 'src/styles/mixins/responsiveMixin';

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.single_blog_item {
  padding: 0 1rem 1rem 1rem;
  text-align: left;
  border-bottom: 3px solid $secondary;
  margin-bottom: 1rem;
  opacity: 0;
  @include animationStandardMixin(fadeIn);
  &_image {
    img {
      width: 100%;
    }
  }
  &_content {
    font-family: 'Roboto', Helvetica, sans-serif;
    text-align: center;
    h1 {
      text-align: center;
      margin-top: 1rem;
      line-height: 1;
    }
    a {
      text-decoration: none;
      color: $primary;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  @include breakpoint(sm) {
    display: flex;
    &_content {
      width: 50%;
      text-align: initial;
      padding-left: 2rem;
      h1 {
        margin-top: 0;
        text-align: initial;
      }
    }
    &_image {
      width: 50%;
    }
  }
}