@import 'src/styles/variables';
@import 'src/styles/mixins/responsiveMixin';

.container {
  font-family: 'Roboto', Helvetica, sans-serif;
  background-color: $gold;
  color: $primary;
  &_inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_column {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    flex-shrink: 4;
    line-height: 1;
    text-align: left;
    h1 {
      text-align: left;
      font-weight: bold;
      margin: 0;
    }
    p {
      display: block;
      width: 100%;
      margin: 0;
      font-size: 1rem;
      text-decoration: none;
    }
    a {
      color: $secondary;
    }
    img {
      padding-bottom: 15px;
      width: 100%;
      max-width: 200px;
    }
    &:nth-of-type(even) {
      flex-shrink: 1;
      text-align: center;
    }
  }

  &_copyright {
    text-align: center;
    font-family: 'Roboto', Helvetica, sans-serif;
    padding: 10px;
    background-color: rgba(0, 0, 0, 1);
    color: $secondary;
    font-size: .7rem;
    z-index: 1;
    h5 {
      margin: 5px 0;
    }
    a {
      text-decoration: none;
      color: $secondary;
    }
  }
  @include breakpoint(sm) {
    &_inner {
      width: 100%;
      flex-direction: row;
    }
    &_column {
      width: 100%;
      justify-content: initial;
      &:nth-of-type(even) {
        text-align: initial;
      }
    }
  }
}