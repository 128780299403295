@import 'src/styles/variables';
@import 'src/styles/mixins/responsiveMixin';

.container {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto 2rem auto;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', Helvetica, sans-serif;
  &_inner {
    width: 100%;
    background-color: rgba(28, 28, 28, .05);
    border-radius: 20px;
    padding: 1rem;
  }
  &_input {
    width: 100%;
    padding-bottom: 5px;
    display: contents;
    input, textarea {
      resize: none;
      width: 100%;
      padding: 10px;
      outline: none;
      border-radius: 10px;
      border: none;
      &:focus-visible {
        outline: none;
      }
    }
    &_error {
      border-width: 1px 1px 1px 10px;
      border-style: solid;
      border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144);
      border-image: initial;
      background: rgb(251, 236, 242);
    }
  }
  &_half {
    display: flex;
    flex-wrap: wrap;
  }
  &_send_button {
    padding: 2rem 0 1rem 0 ;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  @include breakpoint(sm) {
    padding: 0;
    width: 100%;
    &_inner {
      > div {
        display: flex;
      }
    }
    &_half {
      padding: 1rem;
      width: 50%;
    }
    &_send_button {
      padding: 0;
    }
  }
}
