@import "src/styles/variables";

@mixin breakpoint( $breakpoint ) {
  @if map-has-key( $breakpoints, $breakpoint ) {
    @media ( min-width: #{ map-get( $breakpoints, $breakpoint ) } ) {
      font-family: #{ map-get( $breakpoints, $breakpoint ) };
      @content;
    }
  } @else if type_of( $breakpoint ) == number and unit( $breakpoint ) == px or unit( $breakpoint ) == em or unit( $breakpoint ) == rem {
    @media (min-width: $breakpoint ) {
      @content;
    }
  } @else {
    @warn 'Error `#{$breakpoint}` not exist';
  }
}
