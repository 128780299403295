@import 'src/styles/variables';
/* Background Animation */
@keyframes bg-scrolling-reverse {
100% { background-position: $bg-width $bg-height; }
}

@keyframes bg-scrolling {
0% { background-position: $bg-width $bg-height; }
}

/* From Left */
@keyframes moveFromLeftAndFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/* From Right */
@keyframes moveFromRightAndFadeIn {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/* FadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* FadeOut */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
