@import 'src/styles/variables';
@import 'src/styles/animations';
@import 'src/styles/mixins/animationMixin';
@import 'src/styles/mixins/responsiveMixin';

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  &_inner {
    display: flex;
    padding: 1rem 1rem;
    flex-wrap: wrap;
  }
  &_link {
    position: relative;
    @include animationStandardMixin(fadeIn);
    opacity: 0;
    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    &_inner {
      padding: 1rem;
    }
    h5 {
      font-family: 'Roboto', Helvetica, sans-serif;
      position: absolute;
      margin: 0;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      line-height: 1;
      text-decoration: none;
      color: $secondary;
    }
    &:after {
      content: '';
      transition: all;
      transition-duration: .5s;
      position: absolute;
      top: 1rem; left: 1rem; bottom: 1rem; right: 1rem;
      box-shadow: inset 0 0 200px black;
    }
    &:hover:after {
      opacity: 0;
    }
    @include breakpoint(sm) {
      width: 50%;
    }
  }
}