$primary: rgba(28, 28, 28, .8);
$secondary: rgba(220, 220, 220, .9);
$gold: rgb(206, 160, 100);
$bg-url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC';
$bg-width: 50px;
$bg-height: 50px;
$breakpoints: (
        xs: 512px,
        sm: 768px,
        md: 896px,
        lg: 1152px,
        xl: 1280px
);