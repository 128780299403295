@import 'src/styles/variables';

.container {
  position: relative;
  max-width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &_inner {
    background-color: rgba(0, 0, 0, .6);
    padding: 2rem;
    &_background {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      border: 2px solid $secondary;
      padding: 2rem;
    }
    h1 {
      margin: 0;
      z-index: 1;
      color: $secondary;
      font-family: 'Roboto', Helvetica, sans-serif;
    }
  }
}