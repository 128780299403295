@import 'src/styles/variables';

.container {
  width: 200px;
  border: 2px solid $gold;
  background-color: transparent;
  padding: 1rem;
  transition: background-color, color;
  transition-duration: .5s;
  color: $gold;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: $gold;
    color: $primary;
  }
}