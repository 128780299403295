.container {
  position: relative;
  max-height: 60vh;
  overflow: hidden;
  video, img {
    width: 100%;
  }
}
.standard_media {
  height: 35vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.action_button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
}