@import 'src/styles/variables';
@import 'src/styles/animations';
@import 'src/styles/mixins/animationMixin';
@import 'src/styles/mixins/responsiveMixin';

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  &_inner {
    padding: 2rem;
    overflow: hidden;
  }
  &_single_feature {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    opacity: 0;
    @include animationStandardMixin(moveFromLeftAndFadeIn);
    &:nth-of-type(even){
      @include animationStandardMixin(moveFromRightAndFadeIn);
    }
    &:last-child {
      padding-bottom: 0;
    }
    &_image {
      position: relative;
      &:after {
        content: '';
        transition: all;
        transition-duration: .5s;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        box-shadow: inset 0 0 200px black;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &_content {
      font-family: 'Roboto', Helvetica, sans-serif;
      padding: 1rem;
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      &_inner {
        width: 100%;
      }
      h2, p {
        color: $primary;
        display: block;
        width: 100%;
      }
    }
    @include breakpoint(sm) {
      flex-direction: row;
      &:nth-of-type(even){
        flex-direction: row-reverse;
      }
      &_content, &_image {
        width: 100%;
      }
    }
  }
}
